import React, { Component } from "react"
import { Image } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class Magento extends Component {
  render() {
    return (
      <div className="wrapper-skill">
        <Image
          alt="900x500"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
          data-src={require("../../../../images/skill/magento.jpg")}
          className="bannerSkill lazyload"
        />
        <div className="tab-skill">
          <OutboundLink to="/capabilities/php-mysql" activeClassName="active">Php/mysql</OutboundLink>
          <OutboundLink to="/capabilities/jquery-ajax" activeClassName="active">Jquery/Ajax</OutboundLink>
          <OutboundLink to="/capabilities/magento" activeClassName="active">Magento</OutboundLink>
          <OutboundLink to="/capabilities/joomla" activeClassName="active">Joomla</OutboundLink>
          <OutboundLink to="/capabilities/wordpress" activeClassName="active">WordPress</OutboundLink>
          <OutboundLink to="/capabilities/html-css" activeClassName="active">Css3/html5</OutboundLink>
          <OutboundLink to="/capabilities/pts-illus" activeClassName="active">Phptoshop/Illustrator</OutboundLink>
          <OutboundLink to="/capabilities/nginx" activeClassName="active">Apache/Nginx</OutboundLink>
          <OutboundLink to="/capabilities/varnish" activeClassName="active">Varnish</OutboundLink>
        </div>
        <div className="content-skill">
          <h1 className="title-page-skill">Magento</h1>
          <div>
            <div className="left-content-skill">
              <p>An open source CMS i.e. Content Management System for eCommerce, Magento offers comprehensive solutions to sell and purchase products, promoting products and a secured payment gateway. With the help of Magento you will not only double your sales but also will be able to manage your workflow for better performance. Magento is being widely used among all types of businesses starting from small scale business i.e. individual business to larger scale businesses.</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-magento.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill reverse">
              <p>We Expert Magento Developers are the leader in Magento Development with over 8+ years’ industry experience and 150+ customers. Our team of highly experienced and capable Magento developers strives continuously to provide each and every customer with ecommerce solutions customized to suit their needs specifically.</p>
            </div>
            <div className="right-content-skill reverse">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-magento-2.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill">
              <p>Our team of Magento developers and designers are experts in analyzing your requirements, which enables us to design and build your Magento store exactly as per your requirements.</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-magento-3.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Magento;
