import React from "react"

import Layout from "../../components/layout"
import Magento from "../../components/screens/Skill/Magento"
import { Helmet } from "react-helmet"

const MagentoPage = () => (
  <Layout pageInfo={{ pageName: "Magento" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Magento</title>
        <meta name="description" content="Web Development" />
    </Helmet>
    <Magento />
  </Layout>
)

export default MagentoPage
